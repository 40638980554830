// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jX3pxaHZ6ilkGCiXqciJ {\n  margin-top: 10px;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n.FCqvWi3tHQ_Tp6xOg8cN {\n  border-radius: 3px;\n  font-size: 14px;\n}\n.yHRvhA4bkNFmGRayYkY7 {\n  background-color: '#eff294';\n}\n", "",{"version":3,"sources":["webpack://./src/components/selectRadarPanel/selectRadarPanel.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,eAAA;EACA,SAAA;AACJ;AAEA;EACI,kBAAA;EACA,eAAA;AAAJ;AAGA;EACI,2BAAA;AADJ","sourcesContent":[".container {\n    margin-top: 10px;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n\n.chip {\n    border-radius: 3px;\n    font-size: 14px;\n}\n\n.active {\n    background-color: '#eff294';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jX3pxaHZ6ilkGCiXqciJ",
	"chip": "FCqvWi3tHQ_Tp6xOg8cN",
	"active": "yHRvhA4bkNFmGRayYkY7"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TDTogjpZwP6PMlHi0By5 {\n  flex: 1 1 auto;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  padding: 0 5px;\n  margin-top: 10px;\n  margin-bottom: 5px;\n  border-left: 1px solid #d7d7d7;\n}\n.Je7KmwvJalGLLPKeatiy {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  padding: 40px;\n  justify-content: center;\n  align-items: center;\n  color: #b9b9b9;\n  text-align: center;\n}\n.SDXQDTh7RIg_eHZYufgd {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n.WuXZV1fBuoU_uEBnm8Hc {\n  font-size: 10px;\n  font-weight: bold;\n  color: white;\n  background-color: #6a737b;\n  padding: 3px 10px;\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/log/log.module.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,8BAAA;AADJ;AAIA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;AAFJ;AAKA;EACI,qBAAA;EACA,UAAA;EACA,SAAA;AAHJ;AAMA;EACI,eAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;AAJJ","sourcesContent":["@import '../../../../src/styles/global.less';\n\n.container {\n    flex: 1 1 auto;\n    overflow-x: hidden;\n    overflow-y: scroll;\n    padding: 0 5px;\n    margin-top: 10px;\n    margin-bottom: 5px;\n    border-left: 1px solid @gray-hh-color;\n}\n\n.emptyContainer {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n    padding: 40px;\n    justify-content: center;\n    align-items: center;\n    color: @empty-container-color;\n    text-align: center;\n}\n\n.list {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n}\n\n.versionName {\n    font-size: 10px;\n    font-weight: bold;\n    color: white;\n    background-color: @dark-gray-hh-color;\n    padding: 3px 10px;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TDTogjpZwP6PMlHi0By5",
	"emptyContainer": "Je7KmwvJalGLLPKeatiy",
	"list": "SDXQDTh7RIg_eHZYufgd",
	"versionName": "WuXZV1fBuoU_uEBnm8Hc"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZuLn2EReTRiHBBh_bxPu {\n  box-sizing: border-box;\n  padding: 10px 0;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  color: #b9b9b9;\n}\n.kgOdJJUETKyAwB4w_3hS h4 {\n  margin: 0;\n}\n.umdRIsSYQp_DogMS4DxM {\n  display: flex;\n  margin-top: 20px;\n  margin-bottom: 10px;\n  flex-direction: column;\n  gap: 20px;\n  font-size: 14px;\n}\n.mA4GwchVAH4b69d3PKuC {\n  display: flex;\n  padding: 20px;\n  margin-top: 10px;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 12px;\n  font-weight: bolder;\n  border-top: 1px solid gray;\n  background-color: #d7d7d7;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/modals/version/editVersion.module.less"],"names":[],"mappings":"AAEA;EACI,sBAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AADJ;AAIA;EACI,SAAA;AAFJ;AAKA;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;AAHJ;AAMA;EACI,aAAA;EACA,aAAA;EACA,gBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,0BAAA;EACA,yBAAA;AAJJ","sourcesContent":["@import '../../../../styles/global.less';\n\n.emptyComment {\n    box-sizing: border-box;\n    padding: 10px 0;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    color: @empty-container-color;\n}\n\n.commentHeader h4 {\n    margin: 0;\n}\n\n.btnContainer {\n    display: flex;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    flex-direction: column;\n    gap: 20px;\n    font-size: 14px;\n}\n\n.footer {\n    display: flex;\n    padding: 20px;\n    margin-top: 10px;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 12px;\n    font-weight: bolder;\n    border-top: 1px solid gray;\n    background-color: @gray-hh-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyComment": "ZuLn2EReTRiHBBh_bxPu",
	"commentHeader": "kgOdJJUETKyAwB4w_3hS",
	"btnContainer": "umdRIsSYQp_DogMS4DxM",
	"footer": "mA4GwchVAH4b69d3PKuC"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rY__LCan3xyDOPqjARPu {\n  font-size: 12px;\n}\n.abQoS7KtgjoCS1ZnamSo {\n  cursor: pointer;\n}\n.g0TVBDQGzSzZMnSrYVOo {\n  cursor: pointer;\n  background-color: black;\n  color: white;\n}\n.ZdKlWU32ne8LNDD4MVHD {\n  word-wrap: break-word;\n  overflow: hidden;\n}\n.B1_WnEOaDfz0xLdna2Zr {\n  word-wrap: break-word;\n  overflow: hidden;\n  max-height: 200px;\n  transition: all 0.8s;\n  border-bottom: 1px solid gray;\n}\n.G032lrCtNpPMZzA9xYKG {\n  word-wrap: break-word;\n  overflow: hidden;\n  max-height: 0;\n  transition: all 0.3s;\n}\n.ThA8TOjCpv0BhJ0Ozha8 {\n  padding: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/components/legend/dropdown.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAEA;EACI,eAAA;AAAJ;AAGA;EAHI,eAAA;EAKA,uBAAA;EACA,YAAA;AADJ;AAIA;EACI,qBAAA;EACA,gBAAA;AAFJ;AAKA;EAJI,qBAAA;EACA,gBAAA;EAKA,iBAAA;EACA,oBAAA;EACA,6BAAA;AAFJ;AAKA;EAXI,qBAAA;EACA,gBAAA;EAYA,aAAA;EACA,oBAAA;AAFJ;AAKA;EACI,aAAA;AAHJ","sourcesContent":[".container {\n    font-size: 12px;\n}\n\n.title {\n    cursor: pointer;\n}\n\n.titleActive {\n    .title;\n    background-color: black;\n    color: white;\n}\n\n.detail {\n    word-wrap: break-word;\n    overflow: hidden;\n}\n\n.detailOpened {\n    .detail;\n    max-height: 200px;\n    transition: all 0.8s;\n    border-bottom: 1px solid gray;\n}\n\n.detailClosed {\n    .detail;\n    max-height: 0;\n    transition: all 0.3s;\n}\n\n.detailContent {\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "rY__LCan3xyDOPqjARPu",
	"title": "abQoS7KtgjoCS1ZnamSo",
	"titleActive": "g0TVBDQGzSzZMnSrYVOo",
	"detail": "ZdKlWU32ne8LNDD4MVHD",
	"detailOpened": "B1_WnEOaDfz0xLdna2Zr",
	"detailClosed": "G032lrCtNpPMZzA9xYKG",
	"detailContent": "ThA8TOjCpv0BhJ0Ozha8"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S00npnPAUNDECCpWUXvS {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/editPanel/editFieldPanel/radarBaseEditPanel.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "S00npnPAUNDECCpWUXvS"
};
export default ___CSS_LOADER_EXPORT___;

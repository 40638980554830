// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZZgCXNb6c4H5C81d_PIX {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 885px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/editContainer.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;AACJ","sourcesContent":[".layout {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    width: 885px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "ZZgCXNb6c4H5C81d_PIX"
};
export default ___CSS_LOADER_EXPORT___;

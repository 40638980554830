// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZXmDbVdmucBl4uFymKQZ {\n  width: 100%;\n}\n.u3theWKjYLPdx6CwMDfT {\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/components/tab/tabs.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEA;EACI,aAAA;EACA,uBAAA;EACA,SAAA;AAAJ","sourcesContent":[".tabs {\n    width: 100%;\n}\n\n.skeleton {\n    display: flex;\n    justify-content: center;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "ZXmDbVdmucBl4uFymKQZ",
	"skeleton": "u3theWKjYLPdx6CwMDfT"
};
export default ___CSS_LOADER_EXPORT___;

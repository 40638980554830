// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p49qqgMN8b05228ChuRw {\n  padding: 5px 15px;\n  margin: 3px;\n  border: 1px solid #d7d7d7;\n  border-radius: 3px;\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  cursor: pointer;\n}\n.p49qqgMN8b05228ChuRw:hover {\n  background-color: #d7d7d7;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/editPanel/editPropertyButtonPanel/editPropertyButtonPane.module.less"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AADJ;AAEI;EACI,yBAAA;AAAR","sourcesContent":["@import '../../../../styles/global.less';\n\n.main {\n    padding: 5px 15px;\n    margin: 3px;\n    border: 1px solid @gray-hh-color;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    cursor: pointer;\n    &:hover {\n        background-color: @gray-hh-color;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "p49qqgMN8b05228ChuRw"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XS67m6xXtDLm80YJz7S4 {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n.krNU5xMnvHXvRCc2EGNZ {\n  flex-grow: 1;\n  max-width: 1488px;\n  overflow: visible;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layout/layout.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAEA;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".layout {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.container {\n    flex-grow: 1;\n    max-width: 1488px;\n    overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "XS67m6xXtDLm80YJz7S4",
	"container": "krNU5xMnvHXvRCc2EGNZ"
};
export default ___CSS_LOADER_EXPORT___;

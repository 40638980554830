// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Mujzg9Te8Us34CKlTOwc {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/components/radar/controls/controls.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,eAAA;EACA,SAAA;AACJ","sourcesContent":[".sectorControlContainer {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectorControlContainer": "Mujzg9Te8Us34CKlTOwc"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zn3pj_qFdvegqGUeSR4i {\n  position: absolute;\n  z-index: 50;\n  pointer-events: none;\n}\n.zn3pj_qFdvegqGUeSR4i * {\n  filter: none;\n}\n.LvZMMS2lnW6_rsXG8kLO {\n  position: absolute;\n  transform: translate(15px, 15px);\n  pointer-events: none;\n}\n.XNaOdXahLDWDtoOkeJPx {\n  position: relative;\n}\n.a2Qf3BIHoVnvGrJH1eKo,\n.a2Qf3BIHoVnvGrJH1eKo * {\n  cursor: grabbing;\n}\n.x7T44H_Ev7QMqVBq3imI {\n  position: relative;\n  top: 0;\n  left: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/radarContainer/wrapper.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,oBAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAGA;EACI,kBAAA;EACA,gCAAA;EACA,oBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;;EAEI,gBAAA;AAHJ;AAMA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;AAJJ","sourcesContent":[".ghost {\n    position: absolute;\n    z-index: 50;\n    pointer-events: none;\n}\n\n.ghost * {\n    filter: none;\n}\n\n.cursor {\n    position: absolute;\n    transform: translate(15px, 15px);\n    pointer-events: none;\n}\n\n.wrapper {\n    position: relative;\n}\n\n.wrapperDrag,\n.wrapperDrag * {\n    cursor: grabbing;\n}\n\n.layout {\n    position: relative;\n    top: 0;\n    left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ghost": "zn3pj_qFdvegqGUeSR4i",
	"cursor": "LvZMMS2lnW6_rsXG8kLO",
	"wrapper": "XNaOdXahLDWDtoOkeJPx",
	"wrapperDrag": "a2Qf3BIHoVnvGrJH1eKo",
	"layout": "x7T44H_Ev7QMqVBq3imI"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RjhtIzXO3uNtfN8GxgD7 {\n  width: 800px;\n  margin-right: 50px;\n}\n.l650vRUN6To5GN_SW99n {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 800px;\n  height: 800px;\n}\n.AIdPJ6fg35eUEmvw1vjO {\n  display: flex;\n  justify-content: center;\n  min-height: 32px;\n  margin-top: 20px;\n}\n.DoeQTwxrnQRmqcJayOQE {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/components/radar/radar.module.less"],"names":[],"mappings":"AAKA;EACI,YAAA;EACA,kBAAA;AAJJ;AAOA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AALJ;AAQA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AANJ;AASA;EACI,aAAA;EACA,uBAAA;AAPJ","sourcesContent":["@import '../../../../styles/global.less';\n\n@containerSize: 800px;\n@controlHeight: 32px;\n\n.container {\n    width: @containerSize;\n    margin-right: 50px;\n}\n\n.radarContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: @containerSize;\n    height: @containerSize;\n}\n\n.controlContainer {\n    display: flex;\n    justify-content: center;\n    min-height: @controlHeight;\n    margin-top: 20px;\n}\n\n.selectContainer {\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RjhtIzXO3uNtfN8GxgD7",
	"radarContainer": "l650vRUN6To5GN_SW99n",
	"controlContainer": "AIdPJ6fg35eUEmvw1vjO",
	"selectContainer": "DoeQTwxrnQRmqcJayOQE"
};
export default ___CSS_LOADER_EXPORT___;

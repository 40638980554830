// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qzeEyfIYCMl6RMGCjcL2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.WTA5T6zD_Z7xQOFk17Uj {\n  width: 100px;\n  height: 100px;\n  margin: 10px;\n}\n.afAsMn15uqbdaM8t4iWg {\n  width: 200px;\n  margin: 10px;\n  display: block;\n}\n.aqw_RuLNHUrPVYiNfbu1 {\n  width: 100vw;\n  height: 70vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/error/error.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAEA;EACI,YAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAGA;EACI,YAAA;EACA,YAAA;EACA,cAAA;AADJ;AAIA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.imageContainer {\n    width: 100px;\n    height: 100px;\n    margin: 10px;\n}\n\n.messageContainer {\n    width: 200px;\n    margin: 10px;\n    display: block;\n}\n\n.layout {\n    width: 100vw;\n    height: 70vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "qzeEyfIYCMl6RMGCjcL2",
	"imageContainer": "WTA5T6zD_Z7xQOFk17Uj",
	"messageContainer": "afAsMn15uqbdaM8t4iWg",
	"layout": "aqw_RuLNHUrPVYiNfbu1"
};
export default ___CSS_LOADER_EXPORT___;

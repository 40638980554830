// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uRMEzRrKvofFmV4oj0Hd {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: #3c9df2;\n  opacity: 1;\n  transition: opacity 0.2s;\n}\n.YjpuhGGy4HUpS87_x2yw {\n  color: #3c9df2;\n}\n.NwobuVneT_qkBStklaiW {\n  opacity: 0;\n  transition: opacity 1s;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/editPanel/onSaveInfoMessage.module.less"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;EACA,UAAA;EACA,wBAAA;AAAJ;AAGA;EACI,cAAA;AADJ;AAIA;EACI,UAAA;EACA,sBAAA;AAFJ","sourcesContent":["@import '../../../styles/global.less';\n.container {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    color: @label-hh-color;\n    opacity: 1;\n    transition: opacity 0.2s;\n}\n\n.content {\n    color: @label-hh-color;\n}\n\n.hidden {\n    opacity: 0;\n    transition: opacity 1s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uRMEzRrKvofFmV4oj0Hd",
	"content": "YjpuhGGy4HUpS87_x2yw",
	"hidden": "NwobuVneT_qkBStklaiW"
};
export default ___CSS_LOADER_EXPORT___;

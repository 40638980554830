// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XzgBFmb8bF4Uxc1LcAjg {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  color: #5689cf;\n  font-size: 16px;\n}\n.uTpVKMaazcgLII16S96o {\n  color: #5689cf;\n  font-size: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/editPanel/versionStatusContainer.module.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;EACA,eAAA;AADJ;AAIA;EACI,cAAA;EACA,eAAA;AAFJ","sourcesContent":["@color: rgb(86, 137, 207);\n\n.container {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    color: @color;\n    font-size: 16px;\n}\n\n.content {\n    color: @color;\n    font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "XzgBFmb8bF4Uxc1LcAjg",
	"content": "uTpVKMaazcgLII16S96o"
};
export default ___CSS_LOADER_EXPORT___;

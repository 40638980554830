// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lG4shze0rMeOO760lxe7 {\n  padding: 2px;\n}\n.qityoJmRHPxbHki9rQcg {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  color: #969696;\n  font-size: 10px;\n}\n.DRamVEOEQJY3E2AhuTv3 {\n  margin-top: 20px;\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/symbolLegend/symbolLegend.module.less"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;AAIA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,eAAA;AAFJ;AAKA;EACI,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;AAHJ","sourcesContent":["@import '../../styles/global.less';\n\n.svg {\n    padding: 2px;\n}\n\n.item {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    color: @label-color;\n    font-size: 10px;\n}\n\n.legend {\n    margin-top: 20px;\n    width: 100%;\n    justify-content: center;\n    display: flex;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg": "lG4shze0rMeOO760lxe7",
	"item": "qityoJmRHPxbHki9rQcg",
	"legend": "DRamVEOEQJY3E2AhuTv3"
};
export default ___CSS_LOADER_EXPORT___;

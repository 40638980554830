// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fld1T_sXtM_tYY56wg1Q {\n  display: flex;\n  justify-content: space-between;\n}\n.sZtP100zMy0_ZtVGlgk5 {\n  color: #b9b9b9;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/components/radar/selectMenu/select.module.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;AADJ;AAIA;EACI,cAAA;AAFJ","sourcesContent":["@import '../../../../../styles/global.less';\n\n.menuItem {\n    display: flex;\n    justify-content: space-between;\n}\n\n.date {\n    color: @empty-container-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": "Fld1T_sXtM_tYY56wg1Q",
	"date": "sZtP100zMy0_ZtVGlgk5"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".civN3GxNUUOCsyq8ng7r {\n  width: 100%;\n  margin: 0 20px;\n}\n.zJZwxzE4jVJ3M3b5qJpl {\n  margin: 20px 0;\n  display: flex;\n}\n@media (max-width: 767px) {\n  .zJZwxzE4jVJ3M3b5qJpl {\n    flex-direction: column;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/techradar.module.less"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,cAAA;AADJ;AAIA;EACI,cAAA;EACA,aAAA;AAFJ;AAGI;EAAA;IACI,sBAAA;EAAN;AACF","sourcesContent":["@import '../../styles/global.less';\n\n.tabs {\n    width: 100%;\n    margin: 0 20px;\n}\n\n.main {\n    margin: 20px 0;\n    display: flex;\n    @media @screen-only-s {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "civN3GxNUUOCsyq8ng7r",
	"main": "zJZwxzE4jVJ3M3b5qJpl"
};
export default ___CSS_LOADER_EXPORT___;

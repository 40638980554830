// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gqy6JFmd04_DmPIj0Y16 {\n  min-width: 50px;\n}\n.Bnzm6LkhPiWWstkNY8gt {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.IBh8uGuV4AncuUOpRINY {\n  color: #969696;\n  font-size: 18px;\n  font-weight: bold;\n  margin: 0;\n}\n.QkfTGnjN0PEVOlo70sCo {\n  font-size: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/components/propertyItem.module.less"],"names":[],"mappings":"AAEA;EACI,eAAA;AADJ;AAIA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAKA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;AAHJ;AAMA;EACI,eAAA;AAJJ","sourcesContent":["@import '../../../styles/global.less';\n\n.container {\n    min-width: 50px;\n}\n\n.valueContainer {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.value {\n    color: @label-color;\n    font-size: 18px;\n    font-weight: bold;\n    margin: 0;\n}\n\n.label {\n    font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "gqy6JFmd04_DmPIj0Y16",
	"valueContainer": "Bnzm6LkhPiWWstkNY8gt",
	"value": "IBh8uGuV4AncuUOpRINY",
	"label": "QkfTGnjN0PEVOlo70sCo"
};
export default ___CSS_LOADER_EXPORT___;

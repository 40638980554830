// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NQYrsjhc0KQHa1BNd7fK {\n  background-color: #f5f5f5;\n  padding: 5px;\n  font-size: 9px;\n}\n.JDmTAPpreKi0jiqwxajW {\n  color: #39bcc3;\n  font-size: 14px;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/modals/blipEventInfo.module.less"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,YAAA;EACA,cAAA;AADJ;AAIA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAFJ","sourcesContent":["@import '../../../styles/global.less';\n\n.container {\n    background-color: @light-gray-hh-color;\n    padding: 5px;\n    font-size: 9px;\n}\n\n.statusMessage {\n    color: @active-color;\n    font-size: 14px;\n    font-weight: bold;\n    text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NQYrsjhc0KQHa1BNd7fK",
	"statusMessage": "JDmTAPpreKi0jiqwxajW"
};
export default ___CSS_LOADER_EXPORT___;

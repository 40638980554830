// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cuYoPEy0K97vsZ57HP6H {\n  background-color: #f5f5f5;\n  border-bottom: 1px solid #d7d7d7;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/techradar/components/companyName/companyName.module.less"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,gCAAA;AADJ","sourcesContent":["@import '../../../../styles/global.less';\n\n.container {\n    background-color: @light-gray-hh-color;\n    border-bottom: 1px solid @gray-hh-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cuYoPEy0K97vsZ57HP6H"
};
export default ___CSS_LOADER_EXPORT___;

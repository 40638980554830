// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hUv2W6ZpasfPfAJwg416 {\n  background-color: #f5f5f5;\n  border-bottom: 1px solid #d7d7d7;\n}\n.jJ1UbOge0MS2OSM4Z7b7 {\n  width: 100%;\n  padding: 5px 0;\n  display: flex;\n  box-sizing: border-box;\n  margin: 5px 0;\n  gap: 30px;\n}\n.Sprd3MVLYedcbN8Os_H3 {\n  flex-grow: 1;\n}\n.La9DdknxVdmi7GQfsp5o {\n  width: 370px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/constructor/editPanel/mainPanel/mainEditPanel.module.less"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,gCAAA;AADJ;AAIA;EACI,WAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;AAFJ;AAKA;EACI,YAAA;AAHJ;AAMA;EACI,YAAA;AAJJ","sourcesContent":["@import '../../../../styles/global.less';\n\n.background {\n    background-color: @light-gray-hh-color;\n    border-bottom: 1px solid @gray-hh-color;\n}\n\n.container {\n    width: 100%;\n    padding: 5px 0;\n    display: flex;\n    box-sizing: border-box;\n    margin: 5px 0;\n    gap: 30px;\n}\n\n.spacer {\n    flex-grow: 1;\n}\n\n.constSpacer {\n    width: 370px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "hUv2W6ZpasfPfAJwg416",
	"container": "jJ1UbOge0MS2OSM4Z7b7",
	"spacer": "Sprd3MVLYedcbN8Os_H3",
	"constSpacer": "La9DdknxVdmi7GQfsp5o"
};
export default ___CSS_LOADER_EXPORT___;

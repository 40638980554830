// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SlY974wV4RRnBNVb7Odu {\n  cursor: pointer;\n}\n.I_h3g07Vk1tR1eEfGr1M {\n  cursor: grab;\n}\n.whRh3POakIv5WWyt19zv {\n  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));\n}\n.IAM52jWElEAY770bSQGV {\n  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));\n  fill: #fbfb87;\n  stroke: #249fdd;\n}\n.w_8iJdfI9COjiA4hY4Go {\n  font-family: sans-serif;\n  stroke: none;\n  user-select: none;\n}\n.jFFI1YpJwOnXnIWS3Lx4 {\n  font-family: sans-serif;\n  stroke: none;\n  user-select: none;\n  fill: #249fdd;\n}\n.GAMgAizaHAZjgeuR_roz {\n  fill: transparent;\n}\n", "",{"version":3,"sources":["webpack://./src/components/radar/blip.module.less"],"names":[],"mappings":"AAIA;EACI,eAAA;AAHJ;AAMA;EACI,YAAA;AAJJ;AAOA;EACI,mDAAA;AALJ;AAQA;EAHI,mDAAA;EAKA,aAAA;EACA,eAAA;AANJ;AASA;EACI,uBAAA;EACA,YAAA;EACA,iBAAA;AAPJ;AAUA;EALI,uBAAA;EACA,YAAA;EACA,iBAAA;EAKA,aAAA;AANJ;AASA;EACI,iBAAA;AAPJ","sourcesContent":["@blip-color: rgb(255, 244, 40);\n@blip-label-color: rgb(86, 86, 86);\n@blip-hover: rgb(36, 159, 221);\n\n.blipDemonsrative {\n    cursor: pointer;\n}\n\n.blipEditable {\n    cursor: grab;\n}\n\n.blipField {\n    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));\n}\n\n.blipFieldActive {\n    .blipField;\n    fill: rgb(251, 251, 135);\n    stroke: @blip-hover;\n}\n\n.blipText {\n    font-family: sans-serif;\n    stroke: none;\n    user-select: none;\n}\n\n.blipTextActive {\n    .blipText;\n    fill: @blip-hover;\n}\n\n.newBlipFrame {\n    fill: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blipDemonsrative": "SlY974wV4RRnBNVb7Odu",
	"blipEditable": "I_h3g07Vk1tR1eEfGr1M",
	"blipField": "whRh3POakIv5WWyt19zv",
	"blipFieldActive": "IAM52jWElEAY770bSQGV",
	"blipText": "w_8iJdfI9COjiA4hY4Go",
	"blipTextActive": "jFFI1YpJwOnXnIWS3Lx4",
	"newBlipFrame": "GAMgAizaHAZjgeuR_roz"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".owEoJAAXRbwLHHP8pC_y {\n  display: flex;\n  justify-content: center;\n  margin: 1rem;\n}\n.INHdQ6CkU_uoru9GC1R7 {\n  text-transform: uppercase;\n  display: flex;\n  font-size: 30px;\n  justify-content: center;\n  align-items: center;\n  color: #969696;\n}\n.bdULX8GMB1VSUAaYjou1 {\n  display: flex;\n  justify-content: center;\n  background-color: #c3c3c3;\n}\n.SJR09YWfRgOVaJ0gSegm {\n  display: flex;\n  padding: 0 80px;\n  justify-content: space-evenly;\n  align-items: center;\n}\n.W6GfdJhgNsaiFtWUmzIP {\n  height: 600px;\n}\n.Y_vAyQIomYBzK35UVfE3 {\n  padding: 0 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/main/main.module.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,uBAAA;EACA,YAAA;AADJ;AAIA;EACI,yBAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAFJ;AAKA;EACI,aAAA;EACA,uBAAA;EACA,yBAAA;AAHJ;AAMA;EACI,aAAA;EACA,eAAA;EACA,6BAAA;EACA,mBAAA;AAJJ;AAOA;EACI,aAAA;AALJ;AAQA;EACI,eAAA;AANJ","sourcesContent":["@import '../../../src/styles/global.less';\n\n.nav {\n    display: flex;\n    justify-content: center;\n    margin: 1rem;\n}\n\n.header {\n    text-transform: uppercase;\n    display: flex;\n    font-size: 30px;\n    justify-content: center;\n    align-items: center;\n    color: @label-color;\n}\n\n.companiesBlock {\n    display: flex;\n    justify-content: center;\n    background-color: rgb(195, 195, 195);\n}\n\n.presentationBlock {\n    display: flex;\n    padding: 0 80px;\n    justify-content: space-evenly;\n    align-items: center;\n}\n\n.img {\n    height: 600px;\n}\n\n.swiperContainer {\n    padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "owEoJAAXRbwLHHP8pC_y",
	"header": "INHdQ6CkU_uoru9GC1R7",
	"companiesBlock": "bdULX8GMB1VSUAaYjou1",
	"presentationBlock": "SJR09YWfRgOVaJ0gSegm",
	"img": "W6GfdJhgNsaiFtWUmzIP",
	"swiperContainer": "Y_vAyQIomYBzK35UVfE3"
};
export default ___CSS_LOADER_EXPORT___;
